
import React from 'react'
import Feature from '../components/Feature'
import Footer from '../components/Footer'
import '../scss/app.scss'

class Index extends React.Component {

  render() {
    return(
      <>
        <Feature />
        <Footer />
      </>
    )
  }
}

export default Index
