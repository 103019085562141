/**
 * @file feature.js
 * @author Mike Joyce [hello@mikejoyce.io]
 */

import React from 'react'
import Social from '../Social'

const Feature = () => (
  <div className="feature">
    <div className="feature__content">
      <div className="feature__logo" />
      <h1 className="feature__heading">
        Coming Soon
      </h1>
      <Social />
      <div className="feature__icon" />
    </div>
  </div>
)

export default Feature
