/**
 * @file footer.js
 * @author Mike Joyce [hello@mikejoyce.io]
 */

import React from 'react'

const Footer = () => (
  <footer className="footer">
    <div className="footer__icon" />
  </footer>
)

export default Footer
