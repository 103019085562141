/**
 * @file social.js
 * @author Mike Joyce [hello@mikejoyce.io]
 */

import React from 'react'

const Social = () => (
  <div className="social">
    <ul className="social__list">
      <li className="social__list-item">
        <a href="https://www.facebook.com/fredajohnsboutique" target="_blank" rel="noreferrer">
          <div className="social__icon social__icon--facebook" />
        </a>
      </li>
      <li className="social__list-item">
      <a href="https://line.me/R/ti/p/@fredajohns" target="_blank" rel="noreferrer">
        <div className="social__icon social__icon--line" />
      </a>
      </li>
      <li className="social__list-item">
        <a href="https://www.instagram.com/fredajohnsboutique/" target="_blank" rel="noreferrer">
          <div className="social__icon social__icon--instagram" />
        </a>
      </li>
    </ul>
  </div>
)

export default Social
